<script setup async>
import { ref, onMounted } from "vue";
import { CounterAPI } from "counterapi";

const counterValue = ref("");
const counter = new CounterAPI();

onMounted(async () => {
  const res = await counter.up("black12", "black12");
  counterValue.value = res.Count;
  if (counterValue.value >= 1000) {
    counterValue.value = `${(counterValue.value / 1000).toFixed(1)}k`;
  }
});
</script>
<template>
  <div class="relative py-1">
    <ul class="items-center space-x-5 lg:flex h-5">
      <li class="bg-[#776849] px-2">
        <NuxtLink
          href="/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide text-[#0e0d0d] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          >Blacky</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link "
          exact-active-class="exact-active-link"
          >Home</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/about/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link"
          exact-active-class="exact-active-link"
          >AboutMe</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/project/"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link"
          exact-active-class="exact-active-link"
          >Work</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          to="/articles"
          aria-label="Our product"
          title="Our product"
          class="font-medium tracking-wide px-3 py-2 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          active-class="active-link"
          exact-active-class="exact-active-link"
          >Articles</NuxtLink
        >
      </li>
      <li>
        <div class="views ml-[28em]">
          <a href="#" class="flex">
            <svg
              class="w-5 h-5 text-[#776849] font-mono transition-colors duration-200 hover:text-teal-accent-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                clip-rule="evenodd"
              />
            </svg>
            <span
              class="flex items-center ml-2 text-[#776849] font-mono text-sm font-bold"
            >
              {{ counterValue }}
            </span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.font-mono {
  font-family: "Courier New", Courier, monospace;
}

.active-link {
  background-color: #776849;
  color: #0e0d0d;
  padding: 4px;
}

.exact-active-link {
  background-color: #776849;
  color: #0e0d0d;
}
</style>
